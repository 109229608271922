import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/seo'
import TypedText from '../components/typed-text'

const IndexPage = () => (
  <div className="lg:grid flex flex-col">
    <SEO title="Home" />
    <div className="text-content">
      <h1 className="text-5xl sm:text-6xl text-teal-500 font-bold leading-none mb-4">Hello world</h1>
      <h2 className="font-bold mb-4 text-2xl inline-block">
        <TypedText text="My name is Daan Beverdam." />
      </h2>
      <section className="style-text style-links">
        <p>
          I&apos;m a software engineer from Utrecht and love building digital products.
          I specialize in both frontend (React, Vue) and backend (Python, Ruby, PHP, Node.js).
          I&apos;ve worked for many different clients large and small and would love to help you create your next web application. <a href="mailto:hello@daanbeverdam.com">Shoot me an e-mail</a> if you want to get in touch!
        </p>
        <p>
          In my free time I like to <Link to="/photography/">photograph things</Link>.
          I also wrote a few <Link to="/blog/">blog posts</Link> in the past.
          They are quite outdated but still receive some traffic believe it or not, so I left them up.
        </p>
        <p>
          Visit my <a href="https://github.com/daanbeverdam/">Github</a> account to view my past and current projects.
          If you&apos;d like to find out more about me professionally, find me on <a href="https://linkedin.com/in/daanbeverdam/">Linkedin</a>.
          Alternatively, follow me on <a href="https://instagram.com/daanbeverdam/">Instagram</a>.
        </p>
      </section>
    </div>
  </div>
)

export default IndexPage
